import React, { useState } from "react"
import loadable from '@loadable/component'
import { useInView } from 'react-intersection-observer';

import Layout from '../components/Layout'
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { demoLink, ioOption, fadeIn } from '../utils'

import webpageSvg from "../assets/illustratlion/webpage.svg"


import './developers.scss'

// Image Source
const webpageImg = "../assets/illustratlion/webpage.png"

const ModalVideo = loadable(() => import('react-modal-video'))

const DevelopersPage = () => {
  const [isOpen, setOpen] = useState(false)
  const [ refContent, inViewContent, entryContent ] = useInView(ioOption);
  const [ refImg, inViewImg, entryImg ] = useInView(ioOption);



  return (
    <Layout
      title="Include | Developers"
      pageClassName="page-developers"
    > 
      <section className="page-developers__section-hero">
        <div className="page-developers__section-hero__container">
          <div 
            ref={refContent} 
            className={`page-developers__section-hero__container__content ${fadeIn(inViewContent)}`}
          >
            <h1 className="title">Creator Resources</h1>
            <p className="title-tag">
              Everything you need to start building with Include
            </p>
            <div className="button-group">
              {/* <ModalVideo 
                channel='youtube' 
                autoplay 
                isOpen={isOpen} 
                videoId={demoLink} 
                onClose={() => setOpen(false)} 
              /> */}
              <button className="button button-100" onClick={()=> setOpen(true)}>WATCH TUTORIALS</button>
              {/* <Link className="button button-100" to="/">BUILD WITH TEMPLATES</Link> */}
            </div>
          </div>
          {/* <img
            ref={refImg}
            className={`page-developers__section-hero__container__img section-img  ${fadeIn(inViewImg)}`}
            src={ webpageSvg } 
            alt="" 
            loading="eager"
          /> */}
          <div className={`${fadeIn(inViewImg)}`} ref={refImg}>
            <StaticImage 
              className={`page-developers__section-hero__container__img section-img`}
              src={ webpageImg } 
              objectFit="contain"
              layout="fullWidth"
              alt="" 
            />
          </div>

        </div>
      </section>
    </Layout>
  )
}

export default DevelopersPage